@import url(https://fonts.googleapis.com/css2?family=Paytone+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crete+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headline {
  font-family: "Paytone One", sans-serif;
  text-align: center;
  margin-top: 10px;
  font-size: 50px;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

/* .header {
  margin-top: 0px;
  text-align: center;
} */

.content {
  margin-left: 8%;
  margin-right: 8%;
}

.muli {
  font-family: "Muli", sans-serif;
}

.p14 {
  font-size: 14px;
}

.p28 {
  font-size: 28px;
}

.main-content {
  margin-top: 15%;
  font-family: "Muli", sans-serif;
  font-weight: bold;
  font-size: 40px;
}

.sub-content {
  font-family: "Muli", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.bottom-fade {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-name: fadeInBottom;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

.top-fade {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInTop;
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
  }
}

/* PORTO STYLE */

.row {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

.col {
  padding: 20px;
  font-size: 30px;
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 55px;
}

.img--center {
  max-width: 100%;
}

